import React from 'react';
import './Offer.scss';

export function Offer() {
  return (
    <article className="offer-article">
      <h3 className="main-content-header">
        <span>wynagrodzenie </span>
        <span>radcy prawnego</span>
      </h3>
      <div className="main-content-text">
        <p>
          Świadczone przez moją kancelarię kompleksowe usługi prawne
          charakteryzuje wysoka jakość świadczonych usług w&nbsp;przystępnych
          i&nbsp;rozsądnych warunkach cenowych. Wysokość honorariów radcowskich
          zasadniczo uzależniona jest od charakteru sprawy i&nbsp;wartości
          przedmiotu sporu, stopnia jego kompleksowości oraz nakładu pracy
          własnej. Zasady ustalania wynagrodzenia zasadniczo opierają się na
          Rozporządzeniu Ministra Sprawiedliwości z&nbsp;dnia 22 października
          2015 r. w&nbsp;sprawie opłat za czynności radców prawnych
          (t.j.&nbsp;Dz.U.&nbsp;z&nbsp;2023&nbsp;r.&nbsp;poz.&nbsp;1935). Jako
          prawnik mogę jednak zagwarantować indywidualne i&nbsp;elastyczne
          podejście do klienta, a&nbsp;co za tym idzie zawarcie umowy
          uwzględniającej jego oczekiwania w zakresie wyceny usługi. Istotnym
          jest, aby owe warunki uzgodnić na samym początku współpracy
          i&nbsp;zachować przejrzystość wynagrodzenia tak, aby klient mógł
          podjąć racjonalną decyzję co do skorzystania z&nbsp;pomocy
          i&nbsp;dobrze zaplanował swoje wydatki.
        </p>
      </div>
    </article>
  );
}
