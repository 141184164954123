import React from 'react';
import './Main.styles.scss';

export function Main() {
  return (
    <article className="main-article">
      <h3 className="main-content-header main-content-header-about">
        <span>O kancelarii</span>
      </h3>
      <div className="main-content-text">
        <p>
          Kancelaria świadczy kompleksowe usługi prawne skrojone na miarę
          potrzeb i&nbsp;oczekiwań klienta z&nbsp;zakresu różnych dziedzin
          prawa. Fachowe i&nbsp;rzetelne konsultacje, merytoryczne porady
          i&nbsp;opinie prawne, a&nbsp;na dalszym etapie współpracy kompleksowa
          pomoc prawna w&nbsp;reprezentowaniu Państwa i&nbsp;prowadzeniu Państwa
          spraw przed sądami w&nbsp;dążeniu do pomyślnego ich rozstrzygnięcia to
          atuty mojej Kancelarii. Wysokie kwalifikacje i&nbsp;profesjonalizm są
          pochodną gruntownej i&nbsp;solidnej wiedzy podpartej wieloletnią
          edukacją i&nbsp;doświadczeniem zdobytym w&nbsp;najlepszych
          kancelariach. Poza wiedzą merytoryczną wysoce wskazane jest w&nbsp;tej
          profesji wykazanie się empatią i&nbsp;kreatywnością
          w&nbsp;indywidualnym podejściu do klienta. Świadoma ważkości tych
          standardów przywiązuję wielką wagę do osobistego zaangażowania się
          w&nbsp;Państwa sprawy. Poświęcam Państwu swój czas i&nbsp;wyczerpująco
          odpowiadam na Państwa pytania. Zawód radcy prawnego to dla mnie nie
          tylko praca, lecz przede wszystkim pasja i&nbsp;chęć niesienia pomocy
          drugiej osobie.
        </p>
        <p>
          Pomocy prawnej udzielam Państwu stacjonarnie na terenie całego
          województwa dolnośląskiego, a&nbsp;w&nbsp;szczególności we Wrocławiu
          oraz w&nbsp;Jastrzębiu-Zdroju i&nbsp;okolicach po wcześniejszym
          umówieniu terminu. Państwa sprawy prowadzę jednak również zdalnie na
          terenie całego kraju, co umożliwiają nowe technologie postępowo
          wdrażane w&nbsp;sądownictwie. Klientom anglojęzycznym pomocy udzielam
          również za granicą (w&nbsp;formie zdalnej).
        </p>
        <p>
          Aby mogli zasięgnąć Państwo pełnej wiedzy co do oferowanych przeze
          mnie usług prawnych, proszę o&nbsp;zapoznanie się ze stroną mojej
          Kancelarii, a&nbsp;także o&nbsp;nawiązanie kontaktu telefonicznego.
        </p>
        <p>Serdecznie zapraszam,</p>
        <p>r.pr. Magdalena Pawlak</p>
      </div>
    </article>
  );
}
