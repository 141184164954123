import sanityClient from '../sanity/sanity-client';
import { useEffect, useState } from 'react';
import './Blogs.scss';
import { Link } from 'react-router-dom';

function formatDate(isoString) {
  const date = new Date(isoString);

  return new Intl.DateTimeFormat('pl-PL', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(date);
}

export function Blogs() {
  const [blogs, setBlogs] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
            id,
            title,
            publishedAt,
            slug,
            mainImage{
                asset->{
                    _id,
                    url
                },
                alt
            }
        }`
      )
      .then((data) => {
        console.log('data: ', data);
        setBlogs(data);
      })
      .catch(console.error);
  }, []);
  return (
    <article className="blogs-article">
      {/*<div*/}
      {/*  style={{*/}
      {/*    height: '300px',*/}
      {/*  }}*/}
      {/*></div>*/}
      {blogs &&
        blogs.map((blog, index) => {
          return (
            <Link to={`${blog.slug.current}`}>
              <div key={index} className={'blog-list-item'}>
                <div className={'image-container'}>
                  <div
                    className={'image'}
                    style={{
                      backgroundImage: `url(${blog.mainImage.asset.url})`,
                    }}
                  ></div>
                </div>
                <div className={'content'}>
                  <h2>{blog.title}</h2>
                  <div className={'date'}>{formatDate(blog.publishedAt)}</div>
                </div>
              </div>
            </Link>
          );
        })}
    </article>
  );
}
