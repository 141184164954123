import React from 'react';
import './About.styles.scss';

export function About() {
  return (
    <article className="about-article">
      <div className="about-photo"></div>
      <div className="main-content-text">
        <p>
          Nazywam się Magdalena Pawlak i&nbsp;jestem radcą prawnym prowadzącym
          Kancelarię Radcy Prawnego we Wrocławiu. Jestem absolwentką Wydziału
          Prawa, Administracji i&nbsp;Ekonomii Uniwersytetu Wrocławskiego.
          Ukończyłam wyższe studia prawnicze w&nbsp; trybie dziennym&nbsp;–
          stacjonarnym oraz 3-letnią aplikację radcowską w&nbsp;Okręgowej Izbie
          Radców Prawnych we Wrocławiu. Jeszcze w&nbsp;trakcie studiów byłam
          coroczną stypendystką dla najlepszych studentów kierunku prawo,
          a&nbsp;zdobytą wówczas wiedzę wykorzystuję teraz w&nbsp;praktyce
          zawodowej. Biegle władam językiem angielskim, ukończyłam zagraniczne
          kursy i&nbsp;szkolenia językowe, w&nbsp;tym również z&nbsp;prawniczego
          języka angielskiego.
        </p>
        <p>
          Przez wiele lat zdobywałam doświadczenie zawodowe pracując we
          wrocławskich kancelariach zarówno radcowskich jak i&nbsp;adwokackich
          zajmując się szerokim spektrum zagadnień prawnych zarówno
          z&nbsp;zakresu prawa cywilnego, administracyjnego, rodzinnego, prawa
          pracy i&nbsp;prawa karnego.
        </p>
        <p>
          Jako prawnik udzielam pomocy prawnej osobom fizycznym jak
          i&nbsp;prawnym. Zdobyte na przestrzeni lat doświadczenie, wiedza oraz
          determinacja w&nbsp;połączeniu z&nbsp;pasją pozwalają na znalezienie
          rozwiązania nawet w&nbsp;najbardziej skomplikowanych
          i&nbsp;niestandardowych przypadkach. Przyjęte zlecenia opracowuję
          indywidualnie i&nbsp;z&nbsp;najwyższą starannością. Zasadniczo wolę
          polubowne rozwiązanie sporu i&nbsp;zachęcam, by znaleźć dla
          zainteresowanych stron konsensus, co pozwala na uniknięcie nierzadko
          kosztownego postępowania sądowego. Wszędzie zaś tam, gdzie wymagają
          tego interesy Mojego klienta reprezentuję strony i&nbsp;uczestników
          postępowania na drodze sądowej.
        </p>
        <p>
          Specjalizuję się w&nbsp;procesach odszkodowawczych, prawie spadkowym,
          rodzinnym, a&nbsp;także w&nbsp;sprawach kredytów
          indeksowanych/denominowanych do waluty obcej franka szwajcarskiego.
          Współpracę z&nbsp;klientem opieram na wzajemnym zaufaniu,
          wyczerpującej komunikacji oraz rzetelnym przedstawieniu sytuacji
          prawnej, przy uwzględnieniu indywidualnych potrzeb i&nbsp;oczekiwań
          klienta. Nadrzędnym celem jest znalezienie optymalnego dla Klienta
          rozwiązania zaistniałego problemu. W&nbsp;trakcie prowadzenia
          postępowań sądowych Klient jest zawsze na bieżąco informowany o&nbsp;
          przebiegu sprawy.
        </p>
        <p>
          W razie potrzeby współpracuję ze specjalistami z&nbsp;różnych dziedzin
          prawa, w&nbsp;tym notariuszami oraz doradcami podatkowymi, co pozwala
          na świadczenie usług prawnych w&nbsp;sposób kompleksowy.
        </p>
        <p>
          W zakresie swojej działalności prowadzę również wykłady
          i&nbsp;szkolenia z&nbsp;zakresu szeroko pojętego prawa cywilnego.
        </p>
        <p>
          Prywatnie lubię zagraniczne podróże i&nbsp;dobre kino,
          a&nbsp;w&nbsp;wolnych chwilach gram na fortepianie i&nbsp;czytam
          powieści kryminalne.
        </p>
      </div>
    </article>
  );
}
