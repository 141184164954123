import React from 'react';
import './Contact.scss';

export function Contact() {
  return (
    <article className="contact-article">
      <h3 className="main-content-header">
        <span>serdecznie </span>
        <span>zapraszam</span>
      </h3>
      <div className="main-content-text">
        <p>r.pr. Magdalena Pawlak</p>
        <p>
          <em>adres do doręczeń:</em>
        </p>
        <p>
          Kancelaria Radcy Prawnego Magdalena Pawlak
          <br />
          ul. Księcia Witolda 38-40/2
          <br />
          50-202 Wrocław
        </p>
        <p>
          Telefon: <a href="tel:531173467">+48 531 173 467</a>
        </p>
        <p>kancelaria@radcaprawnypawlak.pl</p>
      </div>
    </article>
  );
}
