import './App.scss';
import './contact-footer.scss';
import vars from './vars.scss';

import { Routes, Route, Link, useLocation } from 'react-router-dom';
import { ArticleContainer } from './ArticleContainer';
import { useEffect, useState } from 'react';

function App() {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  console.log('location: ', location);
  const route = (() => {
    const path = location.pathname
      .split('/')
      .slice(1)
      .join('/')
      .replace(/\/$/, '');
    console.log('path2: ', path);
    if (/blog\/.+/.test(path)) {
      return 'concrete-blog';
    }
    switch (path) {
      case 'o-mnie':
        return 'about';
      case 'zakres-uslug':
        return 'services';
      case 'wynagrodzenie':
        return 'offer';
      case 'kontakt':
        return 'contact';
      case 'blog':
        return 'blog';
      default:
        return 'main';
    }
  })();
  function resizeText() {
    const parent = document.querySelector('.subsite-header');
    const child = document.querySelector('.subsite-header h2');
    if (!child) return;
    if (!parent) return;

    const mobileSwitch = (route) => {
      switch (route) {
        case 'main':
          return 0.95;
        case 'blog':
          return 0.7;
        case 'concrete-blog':
          return 0.7;
        default:
          return 0.95;
      }
    };

    const tabletSwitch = (route) => {
      switch (route) {
        case 'main':
          return;
        //return 0.95;
        default:
          return;
      }
    };

    const desktopSwitch = (route) => {
      switch (route) {
        case 'main':
        //return 0.7;
      }
    };

    const factor = (() => {
      let mobile;
      if (parseInt(vars.secondBreakPoint) > window.innerWidth) {
        mobile = mobileSwitch(route);
      }
      let tablet;
      if (!mobile) {
        tablet = tabletSwitch(route);
      }
      let desktop;
      if (!tablet && !mobile) {
        desktop = desktopSwitch(route);
      }
      return mobile || tablet || desktop;
    })();
    if (!factor) {
      child.style.removeProperty('font-size');
      return;
    }
    let fontSize = 20; // Start with a large font size
    child.style.fontSize = fontSize + 'px';
    console.log(child.style.fontSize);
    while (child.scrollWidth < parent.clientWidth * factor && fontSize > 10) {
      fontSize += 4;
      child.style.fontSize = fontSize + 'px';
    }
  }

  useEffect(() => {
    resizeText();
  }, [route]);

  const hideClassIfBlogsPage = route === 'blog' ? 'hide' : '';
  const hideClassIfBlog = route === 'concrete-blog' ? 'hide' : '';
  const isConcreteBlogPage = route === 'concrete-blog';

  window.addEventListener('load', resizeText);
  window.addEventListener('resize', resizeText);

  return (
    <div className="root">
      <div className="top-line"></div>
      <header className="header">
        <h1>Kancelaria Radcy Prawnego</h1>
        <h1>kompleksowa pomoc prawna</h1>
      </header>
      <nav>
        <ul className={!showMenu && 'hide-menu'}>
          <li>
            <Link to="/" onClick={() => setShowMenu(false)}>
              strona główna
            </Link>
          </li>
          <li>
            <Link to="/o-mnie" onClick={() => setShowMenu(false)}>
              o mnie
            </Link>
          </li>
          <li>
            <Link to="/zakres-uslug" onClick={() => setShowMenu(false)}>
              zakres usług
            </Link>
          </li>
          <li>
            <Link to="/wynagrodzenie" onClick={() => setShowMenu(false)}>
              wynagrodzenie
            </Link>
          </li>
          <li>
            <Link to="/kontakt" onClick={() => setShowMenu(false)}>
              kontakt
            </Link>
          </li>
          {/*<li>*/}
          {/*  <Link to="/blog" onClick={() => setShowMenu(false)}>*/}
          {/*    blog*/}
          {/*  </Link>*/}
          {/*</li>*/}
        </ul>
        <button className="btn-mobile-nav">
          <div
            className={showMenu ? 'hide' : ''}
            onClick={() => {
              setShowMenu(!showMenu);
            }}
          >
            <ion-icon name="menu-outline"></ion-icon>
          </div>
          <div
            className={showMenu ? '' : 'hide'}
            onClick={() => {
              setShowMenu(!showMenu);
            }}
          >
            <ion-icon name="close-outline"></ion-icon>
          </div>
        </button>
      </nav>
      <div className="main-grid">
        {!isConcreteBlogPage && (
          <>
            <div
              className={`${hideClassIfBlogsPage} ${hideClassIfBlog} dark-side-block left-margin`}
            ></div>
            <div
              className={`${hideClassIfBlogsPage} ${hideClassIfBlog} top-photos${route ? '-' + route : ''} photo-tile top-left-photo-tile`}
            ></div>
            <div
              className={`${hideClassIfBlogsPage} ${hideClassIfBlog} top-photos${route ? '-' + route : ''} photo-tile top-mid-photo-tile`}
            ></div>
            <div
              className={`${hideClassIfBlogsPage} ${hideClassIfBlog} top-photos${route ? '-' + route : ''} photo-tile top-right-photo-tile`}
            ></div>
            <div
              className={`${hideClassIfBlogsPage} dark-side-block right-margin`}
            ></div>
          </>
        )}
        <div className="light-side-block left-margin"></div>
        <main>
          <header className="subsite-header">
            <Routes>
              <Route
                exact
                path="/"
                element={<h2 className={'main'}>radca</h2>}
              />
              <Route
                exact
                path="/o-mnie"
                element={<h2 className={'about'}>o mnie</h2>}
              />
              <Route
                exact
                path="/zakres-uslug"
                element={<h2 className={'services'}>usługi</h2>}
              />
              <Route
                exact
                path="/wynagrodzenie"
                element={<h2 className={'offer'}>honorarium</h2>}
              />
              <Route
                exact
                path="/kontakt"
                element={<h2 className={'contact'}>kontakt</h2>}
              />
              <Route
                exact
                path="/blog"
                element={<h2 className={'blog'}>blog</h2>}
              />
              <Route
                exact
                path="/blog/*"
                element={<h2 className={'blog'}>blog</h2>}
              />
            </Routes>
            <div className={'lawyer-signature'}>
              <p>MAGDALENA PAWLAK</p>
            </div>
          </header>
          <Routes>
            <Route exact path="/" element={<ArticleContainer tab="main" />} />
            <Route
              exact
              path="/o-mnie"
              element={<ArticleContainer tab="about" />}
            />
            <Route
              exact
              path="/zakres-uslug"
              element={<ArticleContainer tab="services" />}
            />
            <Route
              exact
              path="/wynagrodzenie"
              element={<ArticleContainer tab="offer" />}
            />
            <Route
              exact
              path="/kontakt"
              element={<ArticleContainer tab="contact" />}
            />
            <Route
              exact
              path="/blog"
              element={<ArticleContainer tab="blogs" />}
            />
            <Route
              exact
              path="/blog/:slug"
              element={<ArticleContainer tab="blog" />}
            />
          </Routes>
        </main>
        <div className={`light-side-block right-margin`}></div>
        <div
          className={`${hideClassIfBlogsPage} dark-side-block left-margin`}
        ></div>
        <div
          className={`${hideClassIfBlogsPage} bottom-photos${route ? '-' + route : ''} photo-tile bottom-left-photo-tile`}
        ></div>
        <div className={`${hideClassIfBlogsPage} google-maps-tile`}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5009.28014434402!2d17.01686522889066!3d51.115100618693695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fe91aaaf5716f%3A0x43fa4d64c35b4ed3!2sMagdalena%20Pawlak%20Kancelaria%20Radcy%20Prawnego!5e0!3m2!1sen!2spl!4v1738427544002!5m2!1sen!2spl"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
          <div className={'google-maps-info-tile'}>
            <div className={'desktop'}>
              <a
                href={'https://maps.app.goo.gl/GvK1JpjvpHCNoSnf7'}
                target={'_blank'}
              >
                <p className={'desktop'}>Otwórz Google Maps</p>
                <p className={'mobile'}>Otwórz</p>
                <p className={'mobile'}>Google Maps</p>
              </a>
            </div>
          </div>
        </div>
        {/*<div className="google-maps-tile">*/}
        {/*</div>*/}
        {/*<div className="pin-map" alt="Red Pin"></div>*/}
        {/*</div>*/}
        <div
          className={`${hideClassIfBlogsPage} bottom-photos${route ? '-' + route : ''} photo-tile bottom-right-photo-tile`}
        ></div>
        <div
          className={`${hideClassIfBlogsPage} dark-side-block right-margin`}
        ></div>
        <div></div>
        <div className="email-tile contact-tile">
          <div className="email-icon icon"></div>
          <span>E-mail</span>
          <a
            href={'mailto:kancelaria@radcaprawnypawlak.pl'}
            target={'_blank'}
            className="tile-content"
          >
            kancelaria@radcaprawnypawlak.pl
          </a>
        </div>
        <div className="address-tile contact-tile">
          <div className="home-icon icon"></div>
          <span>Adres</span>
          <div className={'tile-content'}>
            ul. Księcia Witolda 38-40/2
            <br /> 50-202 Wrocław
          </div>
        </div>
        <div className="phone-number-tile contact-tile">
          <div className="phone-icon icon"></div>
          <span>Telefon</span>
          <a className={'tile-content'} href={'tel:531173467'}>
            +48 531 173 467
          </a>
        </div>
        <div></div>
      </div>
      <footer>
        <div>&copy; 2022 Kancelaria | Pawlak</div>
        <div>
          <ul>
            <li>Impressum</li>
            <li>Ochrona danych</li>
          </ul>
        </div>
      </footer>
    </div>
  );
}

export default App;
