import { useEffect, useState } from 'react';
import sanityClient, { projectId, dataset } from '../sanity/sanity-client';
import { useParams } from 'react-router';
import BlockContent from '@sanity/block-content-to-react';
import imageUrlBuilder from '@sanity/image-url';
import './Blog.scss';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  if (source) {
    return builder.image(source);
  }
}
export function Blog() {
  const [blog, setBlog] = useState(null);
  const { slug } = useParams();
  useEffect(() => {
    console.log('slug: ', slug);
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
             }
           },
         body,
        "name": author->name,
        "authorImage": author->image
       }`,
        { slug }
      )
      .then((data) => {
        console.log('blog: ', data);
        setBlog(data[0]);
      })
      .catch(console.error);
  }, [slug]);
  let squareImageUrl;
  if (blog?.mainImage) {
    squareImageUrl = urlFor(blog?.mainImage)
      .width(500)
      .height(500)
      .fit('crop') // Ensures the selected crop is used
      .crop('center') // Uses the predefined crop from Sanity
      .url();
  }

  console.log('squareImageUrl: ', squareImageUrl);

  return (
    <article className="blog-article">
      <h3 className={'blog-title'}>{blog?.title}</h3>
      <div className={'blog-image-container'}>
        <div
          className={'blog-image'}
          style={{
            backgroundImage: `url(${squareImageUrl})`,
          }}
        ></div>
      </div>
      <div className={'blog-content'}>
        <BlockContent
          blocks={blog?.body}
          projectId={projectId}
          dataset={dataset}
        />
      </div>
      {/*<div>*/}
      {/*  <img*/}
      {/*    src={urlFor(blog?.authorImage)?.width(100).url()}*/}
      {/*    alt="Author is Kap"*/}
      {/*  />*/}
      {/*  <h4>{blog?.name}</h4>*/}
      {/*</div>*/}
    </article>
  );
}
