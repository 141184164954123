import { Main } from './subsites/Main';
import './ArticleContainer.scss';
import { About } from './subsites/About';
import { Services } from './subsites/Services';
import { Offer } from './subsites/Offer';
import { Contact } from './subsites/Contact';
import { Blogs } from './subsites/Blogs';
import { Blog } from './subsites/Blog';

export function ArticleContainer(props) {
  return (() => {
    switch (props.tab) {
      case 'main':
        return <Main />;
      case 'about':
        return <About />;
      case 'services':
        return <Services />;
      case 'offer':
        return <Offer />;
      case 'contact':
        return <Contact />;
      case 'blogs':
        return <Blogs />;
      case 'blog':
        return <Blog />;
      default:
        return <Main />;
    }
  })();
}
